PDFJS.workerSrc = "/js/pdf.worker.js";

(function () {


    var pdf = function (selector) {

        $(selector).each(function (index, element) {
            var target = $(element);

            if (target.data("src") === undefined) return;

            var src = target.data("src");
            var scale = target.data("scale") === undefined ? 0 : parseFloat(target.data("scale"));

            PDFJS.getDocument(src).then(function (source) {

                for (var i = 1; i <= source.numPages; i++) {

                    source.getPage(i).then(function (page) {



                        //var defaultScale = target.width() / page.getViewport(1).width;

                        

                        var defaultScale = target.height() / page.getViewport(1).height;
                        // Om en skalning �r definierad tar vi h�nsyn till det annars default bredd
                        scale = scale > 0 ? scale : defaultScale;

                        var viewport = page.getViewport(scale);

                        var canvas = $("<canvas>").appendTo(target)[0];

                        canvas.width = viewport.width;
                        canvas.height = viewport.height;
                        
                        target.width(canvas.width);
     
                        
                        page.render({
                            canvasContext: canvas.getContext('2d'),
                            viewport: viewport
                        });
                    });
                }

            });

        });
    }

    pdf(".pdf");

})();
