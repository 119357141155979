(function ($) {
    //För tooltip

    $('[data-toggle="tooltip"]').tooltip({ trigger: "hover", placement: "bottom" })

    //För logout knapp      
    $('#doLogout').on('click', function (e) {
        var $form = $(this).closest('form');
        e.preventDefault();
        $('#confirm').appendTo("body")
        $('#confirm').modal({ backdrop: 'static', keyboard: false })
            .one('click', '#yes', function () {
                $form.trigger('submit'); // submit the form
            });
    });






    function resizeDecisions(panelSelector, adjustWidth, adjustHeight) {
        //var panelDecision = $(panelSelector);
        $(panelSelector).each(function (index, element) {
            //console.log(index, element);
            var panelDecision = $(element);
            var panelDecisionList = panelDecision.find("ol");
            var targetWidth = panelDecision.parent().width() + adjustWidth;
            var targetHeight = panelDecision.parent().height() + adjustHeight;

            var originalSize = Math.max(parseInt(panelDecisionList.css("fontSize")), 80);  //parseInt("7em");

            panelDecisionList.css("font-size", originalSize + "px");


            while ((panelDecision.get(0).scrollWidth > targetWidth || panelDecision.get(0).scrollHeight > targetHeight) && originalSize >= 1) {

                panelDecisionList.css("fontSize", (originalSize -= 2) + "px");

            }
        });


    }


    resizeDecisions(".audienceDecision", -100, 0);
    resizeDecisions(".audiencePoll", -100, -200);

    $(window).resize(function (e) {

        setTimeout(function () {
            resizeDecisions(".audienceDecision", -100, 0);
            resizeDecisions(".audiencePoll", -100, -200);
        }, 20);
    });

    $(".showComment").click(function (e) {
        var target = $(e.target);
        var comment = $(target.data("target"));
        if (comment.is(":visible")) {
            comment.slideUp();
            target.text("Visa kommentar");

        } else {
            comment.slideDown();
            target.text("Dölj kommentar");
        }
    });

    $(".menu-toggler").click(function (e) {
        e.preventDefault();
        var tree = $(".tree");
        if (tree.hasClass("active")) {
            tree.removeClass("active");
            tree.slideUp();
        } else {
            tree.addClass("active");
            tree.slideDown();
        }
    });

    
    $(".fullscreen-toggler").click(function (e) {
        e.preventDefault();

        var fullscreen = $(".fullscreen");


        if (fullscreen.hasClass("active")) {
            fullscreen.removeClass("active");

            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            
        } else {
            var element = document.documentElement

            fullscreen.addClass("active");

            if (element.requestFullScreen) {
                element.requestFullScreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen();
            }
        }





    });



    $(".closeMenu").click(function (e) {
        e.preventDefault();
        var tree = $(".tree");
            tree.removeClass("active");
            tree.slideUp();

    });

    $.fn.pointers = function () {
        if (this.length > 1) return;

        return $("*[data-target=" + this.first().attr("id") + "]");
    }

    $(".view").on("enter", function () {
        $(this).addClass("active");
        $(this).pointers().each(function (index, element) {
            $(element).siblings().removeClass("active");
            $(element).addClass("active");

        });
    });
    $(".view").on("leave", function () {

        $(this).removeClass("active");
        $(this).pointers().each(function (index, element) {
            $(element).removeClass("active");

        });
    });
    $(window).scroll(function (event) {
        $(".view.active").trigger("leave");
        $(".view").each(function (index, element) {

            //console.log(index, Math.round($(element).offset().top), event.target.body.scrollTop);


            if (Math.round($(element).offset().top) == event.target.body.scrollTop) {

                $(element).trigger("enter");
            }
        });
    })

    $(window).resize(function (event) {
        $("li.active").click();
    });



    function getCommand(code) {
        return new Promise(function (success, failure) {
            var command;
            //console.log(code)
            switch (code) {
                //Key 1 - 9
                case 49:
                case 50:
                case 51:
                case 52:
                case 53:
                case 54:
                case 55:
                case 56:
                case 57:
                    command = {
                        fn: navigateTo,
                        args: [String.fromCharCode(code)]
                    }
                    break;
                //Key B
                case 66:
                    command = {
                        fn: navigateTo,
                        args: [String.fromCharCode(code)]
                    }
                    break;
                //Arrow down and arrow right
                case 39:
                case 40:
                    command = {
                        fn: next,
                        args: [String.fromCharCode(code)]
                    }
                    break;
                //Arrow up and arrow left
                case 37:
                case 38:
                    command = {
                        fn: prev,
                        args: [String.fromCharCode(code)]
                    }
                    break;
                
                //Key G
                case 71:

                    if ($($("*[data-redirecturl]")[0]).data('redirecturl') != null) {
                        window.location = $($("*[data-redirecturl]")[0]).data('redirecturl');
                    } 
                    
                    break;
                //Numeric keyboard, key 1 - 9
                case 97:
                case 98:
                case 99:
                case 100:
                case 101:
                case 102:
                case 103:
                case 104:
                case 105:
                    command = {
                        fn: navigateTo,
                        args: [String.fromCharCode(( code - 48 ))]
                    }
                    break;

            }
            if (command) {
                command.execute = function () {
                    command.fn(command.args);
                };
                success(command);
            } else {
                failure({ message: "No command configured for: " + code });
            }
        });
    };

    //För att visa dolda knappar avseende connection mm key 63 = frågetecken
    $("body").keypress(function (e) {
        if (e.which == 63) {
            $(".votingControl").toggle();
        }
    });


    $("ul.inpage>li").click(function (event) {

        var targetId = $(this).data("target");

        if (targetId == "G") {
            window.location = $(this).data("redirecturl");
        } else {
            navigateTo(targetId);
        }


        event.preventDefault();
    });

    function navigateTo(id) {

        if ($(".voteStatusImg").is(":visible")) {
            return;
        }
        var target = $("#" + id);
        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 500);
        }
    }

    function getActiveView() {
        var activeView = $(".view.active");
        if (activeView.length == 0) {
            activeView = $(".view")[0];
        }
        console.log("activeView", activeView);
        return $(activeView);
    }

    function next() {
        console.log("next");
        navigateTo(getActiveView().next().attr("id"));
    }

    function prev() {
        console.log("prev");
        navigateTo(getActiveView().prev().attr("id"));
    }

    $(document.body).keydown(function (event) {
        getCommand(event.which)
            .then(function (command) {
                command.execute();
            }, function (error) {
                console.log(error.message)
            });

    });



})(jQuery);
