
$(function () {
    //General functions not depending on mentometer
    $(".showHistory").click(function (e) {
        
        var btn = $(this);

        btn.closest(".view").find(".resultHistory").toggle("slow", function () {
            var isHistoryVisible = $(this).is(":visible");

            if (!isHistoryVisible) {
                //$(".vote-options-description").fadeIn();
                btn.html("Visa tidigare omröstning");
                $(".result").fadeIn();
                //if (btn.closest(".view").find(".result").is(":visible") == false) {
                //    $(".vote-options-description").fadeIn();
                //}   

            } else {
                $(".vote-options-description").fadeOut();
                btn.html("Dölj tidigare omröstning");
                $(".result").fadeOut();
                

            }
        });
    });



    //Check if voting exist
    if ($('[data-voting="true"]').length > 0) {

        //Include hub
        $.getScript('/signalr/hubs', function () {


            // Declare a proxy to reference the hub.
            var hub = $.connection.votingHub;
            var res = "";

            //CONNECTION STATUS
            hub.on("conStatus", function (statusCode) {
                if (statusCode == "0") {
                    $(".conStatusImg").attr('src', '/img/red.png');
                    $(".startVoting").hide();
                }
                if (statusCode == "1") {
                    $(".conStatusImg").attr('src', '/img/green.png');
                    $(".connectionAlert").hide();
                    $(".startVoting").show();
                }
                if (statusCode == "2") {
                    $(".conStatusImg").attr('src', '/img/ajax-loader.gif');
                    $(".startVoting").hide();
                }
            });

            //CONNECTION ERROR
            hub.on("conError", function (message) {
                $(".connectionAlert").html(message);
                $(".connectionAlert").show();
            });

            //VOTE STATUS
            hub.on("voteStatus", function (statusCode) {
                
                if (statusCode == "0") {
                    $(".voteStatusImg").hide();
                    $(".startVoting").delay(2000).show(0);          //Delay för att man inte av misstag skall klicka på start igen
                    $(".stopVoting").hide();
                }
                if (statusCode == "1") {
                    $(".voteStatusImg").show();

                    $(".result").hide("slow", function () { });
                    $(".startVoting").hide();
                    $(".stopVoting").delay(2000).show(0);

                }
            });

            //VOTE PROGRESS
            var x = 0;
            hub.on("voteProgress", function (keypadResult) {
                //$(".voteProgressOutput").append(keypadResult + "<br>");
                $(".voteProgressOutput").html(keypadResult)


                //Blinkande indikator när folk röstar, pågår 5 sek efter sista tryck
                x = x + 1;
                if (x >= 1 && $(".voteProgress").hasClass("blink") == false) {
                    $(".voteProgress").addClass('blink');
                }
                setTimeout(function () {
                    x = x - 1;
                    if (x <= 0 && $(".voteProgress").hasClass("blink") == true) {
                        $(".voteProgress").removeClass('blink');
                    }
                }, 5000);
              

            });

            //VOTE RESULT
            hub.on("voteResult", function (res) {
                $(".voteResultOutput").html(res);

            });
            //SHOW RESULT
            hub.on("showResult",  function (type, res, id) {

                    for (var key in res) {
                        var altBar = "#" + type + "_" + id + " .resultBar" + key;
                        var altDigit = "#" + type + "_" + id + " .resultDigit" + key

                        //Dra ner barens längd till 90% för att allt ska få plats,
                        var altBarPercentage = ((res[key] * 0.9) + '%');
                        
                        var percentage = res[key] + '%';

                        $(altDigit).html(percentage);
                        $(altBar).width(altBarPercentage);
                    }
                $("#" + type + "_" + id + " .vote-options-description").hide();

                    
                if($("#" + type + "_" + id + " .resultHistory").is(":visible")){
                    $("#" + type + "_" + id).closest(".view").find(".showHistory").click();
                };
                $("#" + type + "_" + id + " .result").toggle("slow", function () {
                });
            });




            //HUB STARTED
            $.connection.hub.start().done(function () {
               

                //Automatic start
                hub.server.connect(); 

                //Close connection when leaving page  - Behövs ej????
                $(window).on('beforeunload', function () {
                    hub.server.disconnect();
                });

                //Parameters
                var numberOfOptions = 0;
                var caseStudyPage = 0;
                var votingType = "none";
                //if ($('#numberOfVoteOtions').val() != 'undefined') {
                //    numberOfOptions = $('#numberOfVoteOtions').val();
                //}
                //if ($('#caseStudyPage').val() != 'undefined'){
                //    caseStudyPage = $('#caseStudyPage').val();
                //}
                //if ($('#votingType').val() != 'undefined') {
                //    votingType = $('#votingType').val();
                //}

                //Functions
                $('.manualConnect').click(function () {
                    // Call the Send method on the hub.
                    hub.server.connect();

                });
                $('.manualDisconnect').click(function () {
                    // Call the Send method on the hub.
                    hub.server.disconnect();
                });
                $('.startVoting').click(function () {
                    // Call the Send method on the hub.

                    numberOfOptions = $(this).data("voteoptions");
                    caseStudyPage = $(this).data("page");
                    votingType = $(this).data("type");
                   
                    $(".showHistory").html("Visa tidigare omröstning");
                    $(".resultHistory").fadeOut();
                   
                    


                    hub.server.startVoting(votingType, caseStudyPage, numberOfOptions, 1, false, 1, "60");
                    
                    //$("#startVoting").hide();
                    //$("#stopVoting").show();

                });
                $('.stopVoting').click(function () {
                    // Call the Send method on the hub.
                    $('.stopVoting').hide();
                    
                    hub.server.stopVoting();

                    //$("#startVoting").show();
                    //$("#stopVoting").hide();
                });
            });




        });


    }



});